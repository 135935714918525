<template>
  <div>
    <!--Modal AddEditProcesoElectoralGasto-->
    <div v-if="edit.mostrarModal">
      <add-edit-proceso-electoral-gasto
        v-model="edit.mostrarModal"
        :proceso-electoral-gasto-id="edit.procesoElectoralGastoId"
        @onSave="handleAddEditProcesoElectoralGastoSaved"
      ></add-edit-proceso-electoral-gasto>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                <i class="fa fa-coins fa-fw"></i> Cuentas
              </h3>
            </div>
            <div class="card-toolbar">
              <a
                href="#"
                class="btn btn-primary font-weight-bold mr-2"
                @click.prevent="doAddEditProcesoElectoralGasto()"
                ><i class="la la-plus"></i> Nuevo</a
              >
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-hover table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>
                      Usuario
                    </th>
                    <th>
                      Documento
                    </th>
                    <th>
                      Concepto / Proveedor
                    </th>
                    <th>
                      Importe
                    </th>
                    <th>
                      Estado
                    </th>
                    <th>
                      Doc. Actual
                    </th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="cuenta in cuentas"
                    :key="cuenta.procesoElectoralGastoId"
                  >
                    <td>
                      {{ cuenta.fechaRegistro | date }}
                    </td>
                    <td>
                      {{ cuenta.usuario }}
                    </td>
                    <td>
                      {{ cuenta.tipoDocumento }}<br />
                      <small
                        v-if="cuenta.numeroDocumentoGasto != ''"
                        class="text-muted"
                        >Nro. : {{ cuenta.numeroDocumentoGasto }}</small
                      >
                    </td>
                    <td>
                      {{ cuenta.concepto }}<br />
                      <small class="text-muted">{{ cuenta.proveedor }}</small>
                    </td>
                    <td>
                      {{ cuenta.importe.toFixed(2) }}
                    </td>
                    <td>
                      <span
                        v-if="cuenta.estadoAprobacion == 'PEN'"
                        class="label label-lg label-light-warning label-inline"
                        >Pendiente</span
                      >
                      <span
                        v-if="cuenta.estadoAprobacion == 'APR'"
                        class="label label-lg label-light-success label-inline"
                      >
                        Aprobado</span
                      >
                      <span
                        v-if="cuenta.estadoAprobacion == 'REC'"
                        class="label label-lg label-light-danger label-inline"
                        >Rechazado
                      </span>
                      <i
                        v-if="
                          cuenta.estadoAprobacion == 'REC' &&
                            cuenta.observacionesAprobacion != null
                        "
                        class="far fa-comment icon-md pl-2"
                        @click.prevent="
                          doMdlObservacionComentario(
                            cuenta.observacionesAprobacion
                          )
                        "
                      ></i>
                    </td>
                    <td>
                      <a
                        class="text-primary"
                        :href="`${filesUrl + cuenta.rutaDocumentoGasto}`"
                        target="_blank"
                        ><i class="fas fa-eye fa-fw"></i> Ver Documento</a
                      >
                    </td>
                    <td>
                      <b-dropdown right>
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item
                          v-if="
                            cuenta.estadoAprobacion == 'PEN' ||
                              cuenta.estadoAprobacion == 'REC'
                          "
                          href="#"
                          @click.prevent="
                            doAddEditProcesoElectoralGasto(
                              cuenta.procesoElectoralGastoId
                            )
                          "
                        >
                          <span class="navi-icon"
                            ><i class="fa fa-pen text-dark"></i> Editar</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Secciones para los modals-->
    <!--Modal Observacion-->
    <div>
      <b-modal
        id="observacion-modal"
        size="md"
        title="Observaciones"
        hide-footer
      >
        <div class="mb-3">
          <p>
            <span class="pl-2" style="font-size: initial;">{{
              coment.observacion
            }}</span>
          </p>
        </div>
      </b-modal>
    </div>

    <!--Modal Formulario reporte-->
    <div>
      <b-modal id="cabecera-reporte-modal" size="md" title="Generar Reporte">
        <div class="row pt-1">
          <div class="col-md-12">
            <label class="font-weight-bold">Agencia</label>
            <b-form-input
              id="input-1"
              v-model="report.agencia"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label class="font-weight-bold">Actividad</label>
            <b-form-input
              id="input-2"
              v-model="report.actividad"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label class="font-weight-bold">Proyecto</label>
            <b-form-input
              id="input-3"
              v-model="report.proyecto"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-12">
            <label class="font-weight-bold">Fecha de la Actividad</label>
            <b-form-datepicker
              id="datepicker-1"
              class="form-control"
              v-model="report.fechaActividad"
              required
            >
            </b-form-datepicker>
          </div>
        </div>
        <div slot="modal-footer" class="d-flex justify-content-between w-100">
          <span class="alignment-right">
            <button
              type="button"
              class="btn btn-success font-weight-bold"
              @click.prevent="downloadReporteProcesoElectoralGasto()"
            >
              <i class="far fa-file-excel"></i> Generar Reporte
            </button>
          </span>
        </div>
      </b-modal>
    </div>

    <!--Modal Descarga Reporte-->
    <div>
      <b-modal
        id="download-modal"
        size="md"
        title="Descargar Reporte"
        hide-footer
      >
        <div v-if="download.hasDownloadResult">
          <div class="mb-3">
            <p>
              Utilice el siguiente enlace para descargar o compartir el reporte:
            </p>
            <div class="card p-2 mb-2 border bg-light">
              {{ filesUrl + download.reportUrl }}
            </div>
            <a
              class="btn btn-default"
              :href="`${filesUrl + download.reportUrl}`"
              target="_blank"
              ><i class="fa fa-download fa-fw"></i> Descargar reporte</a
            >
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
.alignment-right {
  float: right !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Table from "../pages/vue-bootstrap/Table.vue";
import addEditProcesoElectoralGasto from "@/components/spending/add-edit-proceso-electoral-gasto";
import Swal from "sweetalert2";

/*intervalos de tiempo de Inactividad*/
import {
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
} from "@/helpers/constants.js";

export default {
  name: "list-electoral-process-user-expenditure",
  components: {
    addEditProcesoElectoralGasto,
  },
  props: {},
  data: () => ({
    cuentas: [],
    edit: {
      mostrarModal: false,
      procesoElectoralGastoId: null,
    },
    coment: {
      observacion: "",
    },
    report: {
      agencia: "",
      actividad: "",
      proyecto: "",
      fechaActividad: null,
    },
    download: {
      hasDownloadResult: false,
      reportUrl: null,
    },
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {},
  methods: {
    doAddEditProcesoElectoralGasto: function(procesoElectoralGastoId) {
      this.edit.mostrarModal = true;
      this.edit.procesoElectoralGastoId = procesoElectoralGastoId;
    },
    handleAddEditProcesoElectoralGastoSaved: function(obj) {
      //alert(JSON.stringify(obj));
      this.fetchProcesoElectoralGastos();
    },
    doMdlObservacionComentario: function(observacion) {
      this.coment.observacion = observacion;
      this.$bvModal.show("observacion-modal");
    },
    doMdlCabeceraReporte: function() {
      this.report.agencia = "";
      this.report.actividad = "";
      this.report.proyecto = "";
      this.report.fechaActividad = null;
      this.$bvModal.show("cabecera-reporte-modal");
    },
    fetchProcesoElectoralGastos: function() {
      this.$http
        .get(
          this.apiRoute(
            "ElectoralProcessSpending",
            "ListProcesoElectoralGastos"
          ),
          {
            params: {
              verTodo: false,
            },
          }
        )
        .then(function(response) {
          this.cuentas = response.body.data.items;
        });
    },
    downloadReporteProcesoElectoralGasto: function() {
      var model = {
        ...this.report,
      };

      this.showLoading();
      this.download.hasDownloadResult = false;

      this.$http
        .post(
          this.apiRoute(
            "ElectoralProcessSpending",
            "GetReporteProcesoElectoralGastos"
          ),
          model
        )
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.download.hasDownloadResult = true;
            this.download.reportUrl = response.body.data.reportUrl;
            this.$bvModal.hide("cabecera-reporte-modal");
            this.$bvModal.show("download-modal");
            this.showLoading(false);
          }
        }, this.mostrarMensajeExcepcionVueResource);
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {},
  mounted: function() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mis Cuentas" }]);
    this.fetchProcesoElectoralGastos();
    this.activateActivityTracker();
  },
  destoyed: function() {},
};
</script>
